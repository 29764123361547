import { css } from 'styled-components';

export const TruncatedText = ({ noWrap = 'nowrap' }: { noWrap?: 'normal' | 'nowrap' }) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${noWrap};

  // Truncate Google Translate
  &:has(span) {
    &:has(font) {
      span {
        overflow: visible;
        display: inline-flex;
        min-width: 0;
        max-width: -webkit-fill-available;
      }

      font {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: ${noWrap};
      }
    }
  }

  &:has(font) {
    > font {
      overflow: visible;
      display: inline-flex;
      min-width: 0;
      max-width: -webkit-fill-available;

      font {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: ${noWrap};
      }
    }
  }
`;

export const MultilineTruncatedText = ({ lineClamp = 2 }: { lineClamp?: number }) => css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lineClamp};
  ${TruncatedText({ noWrap: 'normal' })};
`;
