import { number, string } from 'prop-types';

import { ToolbarContext } from '../contexts/EditorToolbarContext';
import { HeapModalActionId } from '../types/TaskTracking';

// Id format to follow [action]-[target]-[additional context if needed]
const buttonIdRegistry = [
  'account-status-banner-button',
  'account-status-banner-secondary-button',
  'account-text-color',
  'account-text-color-removal-button',
  'activity-content-recovery',
  'activity-log-filters-button',
  'add-and-edit-template',
  'add-credit-card-button',
  'add-from-google-drive-button',
  'add-google-doc-file-by-link-button',
  'add-group-button',
  'add-group-button-close-menu',
  'add-group-button-open-menu',
  'add-more-files-button',
  'add-subject-button-trigger-desktop',
  'add-survey-question-button',
  'add-teammate-button',
  'add-teammate-button-modal-open',
  'add-template',
  'addon-full-screen-overlay-cancel-button',
  'addon-full-screen-overlay-primary-button',
  'addon-trainual-plus-full-screen-overlay-cancel-button',
  'addon-trainual-plus-full-screen-overlay-primary-button',
  `ai-bot-${string}-button`,
  'ai-glowing-icon-button',
  'ai-outline-add-topic-primary',
  'ai-outline-add-topic-secondary',
  'ai-smart-test-button',
  'ai-start-outlining-button',
  'all-curriculums-report-filter-btn',
  'all-users-report-filter-btn',
  'apply-promo-code-button',
  'assign-new-curriculums-modal-button',
  'billing-page-trainual-plus-card-add-to-plan-button',
  'brand-styles-color-picker-button',
  'build-org-chart-button',
  'bulk-move-esig-confirm-button',
  'cancel-assign-subjects',
  'cancel-create-curriculum-fullscreen-overlay',
  'cancel-custom-ai-outline',
  'cancel-documents-import-button',
  'cancel-group-deletion',
  'cancel-linked-content',
  'cancel-topic-cover-changes',
  'change-subject-status-dropdown',
  'checkout-pay-primary-button',
  'clear-selected-color-button',
  'close-before-you-get-started-bulk-upload-modal',
  'close-content-button',
  'close-cx-implementation-scheduler-modal',
  'close-responsibility-details-modal',
  'color-picker-logo-bg-button',
  'color-picker-selection-button',
  'color-picker-removal-button',
  'compare-plans-button',
  'compose-button-open-modal',
  'compose-button-submit',
  'compose-feedback-cancelled',
  'compose-feedback-submitted',
  'compose-group-ai-description',
  'compose-question-answer-button',
  'compose-role-ai-generate-chart',
  'compose-start-feedback',
  'compose-step-button-generate',
  'compose-step-button-regenerate',
  'compose-subject-ai-description',
  'compose-survey-ai-description',
  'configure-plan-cancel-button',
  'configure-plan-primary-button',
  'confirm-assign-subjects',
  'confirm-group-deletion',
  'connect-content-button',
  'connect-slack-button',
  'connect-sso-button',
  'consider-this-plan-cancel-button',
  'consider-this-plan-talk-to-sales-link',
  'content-banner-dismiss-button',
  'content-flyout-cancel-button',
  'content-flyout-connect-content-button',
  'content-flyout-create-content-button',
  'content-library-filter-button',
  'content-library-sort-by-button',
  'content-library-view-by-button',
  'create-content-button',
  'create-curriculum-inline',
  'create-group-inline',
  'create-responsibility-inline',
  'create-step-external-link',
  'create-subject-button-trigger-desktop',
  'customize-training-path',
  'delegation-planner-add-new-board-button',
  'delegation-planner-add-new-group-button',
  'delegation-planner-apply-changes-button',
  'delegation-planner-cancel-move-responsibility-button',
  'delegation-planner-cancel-responsibility-content-button',
  'delegation-planner-celebratory-modal-close-button',
  'delegation-planner-celebratory-modal-create-board-button',
  'delegation-planner-save-move-responsibility-button',
  'delegation-planner-save-responsibility-content-button',
  'directory_invite_modal_trigger',
  'disconnect-finch-button',
  'disconnect-sso-button',
  'dismiss-banner-all-content-button',
  'dismiss-banner-company-button',
  'dismiss-banner-everyone-group-edit-button',
  'dismiss-banner-groups-index-button',
  'dismiss-banner-policy-button',
  'dismiss-banner-process-button',
  'dismiss-trainual-plus-widget-button',
  'dismiss-trainual-u-card-button',
  'download-org-chart-button',
  'e-signature-paywall-banner-buy-now-button',
  'e-signature-paywall-banner-dismiss-dropdown-button',
  'edit-group-chart',
  'edit-org-chart-button',
  'edit-reminder-settings-button',
  'edit-teammate-button-modal-open',
  'edit-user-button',
  'editor-cell-color-removal-button',
  'editor-highlight-removal-button',
  'editor-highlight-text-button',
  'editor-save-button',
  'editor-table-color-button',
  'editor-text-color-button',
  'editor-text-color-removal-button',
  'element-report-filter-btn',
  'explore-button',
  'failed-import-back-to-upload',
  'failed-import-close',
  'failed-import-finish-later',
  'failed-import-need-help',
  'filter-panel-slideout-button',
  'flowchart-content-button',
  'flowchart-group-button',
  'flowchart-person-button',
  'flowchart-redo-button',
  'flowchart-shapes-button',
  'flowchart-shape-color-button',
  'flowchart-undo-button',
  'flyout-primary-button',
  'flyout-secondary-button',
  'follow-user-button',
  'forgot-password-reset-button',
  'go-home-button',
  'go-to-trainual-u-card-button',
  'group-chart-limited-access-dismiss',
  'group-chart-limited-access-upgrade',
  'group-description-button-generate',
  'group-description-button-regenerate',
  'group-sort-order-dropdown-button',
  'groups-content-table-manage-button',
  'groups-header-button',
  'groups-members-table-manage-button',
  'hide-completed-task-list-button',
  'horizontal-line-color-button',
  'hris-enabled-BambooHR',
  'hris-enabled-Finch',
  'hris-enabled-Justworks',
  'hris-enabled-Paylocity',
  'hris-enabled-QuickBooks',
  'hris-enabled-undefined',
  'hris-enabled-Zenefits',
  'import-all-documents-button',
  'import-documents-button',
  'import-teammates-close',
  'import-teammates-previous-step',
  'individual-user-report-filter-btn',
  'insert-editor-toolbar-button',
  'invite-my-team-button',
  'insert-video-response-url-button',
  'invite-users-button',
  'login-submit-button',
  'manage-plan-cancel-button',
  'manage-plan-primary-button',
  'manage-seats-button',
  'mark-completed-button-step',
  'mobile-nudge-desktop-overlay-continue-to-mobile-button',
  'mobile-nudge-native-app-overlay-continue-to-mobile-button',
  'mobile-nudge-overlay-download-app-button',
  'navigate-to-template-page',
  'need-more-seats-need-help-link',
  'need-more-seats-request-quote-link',
  'new-step-button',
  'next-content-button',
  'next-course-button',
  'next-editor-button',
  'next-flowchart-button',
  'next-survey-button',
  'next-video-button',
  'next-survey-question-button',
  'notify-team-changes-button',
  'onboarding-choose-variation-step-back',
  'onboarding-choose-variation-step-submit',
  'onboarding-customize-accent-step-back',
  'onboarding-customize-experience-step-back',
  'onboarding-customize-experience-step-submit',
  'onboarding-customize-logo-step-back',
  'onboarding-customize-logo-step-submit',
  'onboarding-finish-setup',
  'onboarding-remove-avatar-button',
  'onboarding-remove-logo-button',
  'onboarding-upload-avatar-button',
  'onboarding-upload-logo-button',
  'onboarding-user-info-step-submit',
  'open-ai-refresh-course-suggestions-button',
  'open-create-group-modal',
  'open-create-groups-modal-button',
  'open-create-subject-modal',
  'open-new-step-button',
  'open-org-chart',
  'open-supershare-modal',
  'paywall-btn',
  'paywalled-finch-connect-btn',
  'paywalled-role-chart-btn',
  'paywalled-sso-connect-btn',
  'photo-loader-remove-image-button',
  'photo-loader-upload-image-button',
  'pick-a-plan-cancel-button',
  'pick-a-plan-primary-button',
  'pick-files-from-google-drive-button',
  'previous-content-button',
  'previous-editor-button',
  'previous-survey-question-button',
  'public-share-banner-button',
  'public-share-next-step-btn',
  'public-share-prev-step-btn',
  'ran-out-of-seats-need-help',
  'ran-out-of-seats-upgrade-later',
  'ran-out-of-seats-upgrade-plan',
  're-record-video-response-answer-button',
  'record-video-response-answer-button',
  'refer-a-friend-button',
  'registration-submit-button',
  'remove-emoji-button',
  'report-filter-apply-button',
  'report-filter-clear-button',
  'request-content-library-button',
  'reset-password-submit-button',
  'retake-test-button',
  'rich-textarea-cancel-button',
  'rich-textarea-save-button',
  'save-account-settings-button',
  'save-brand-styles',
  'save-color-picker-changes',
  'save-content-page-default-view',
  'save-custom-ai-outline',
  'save-custom-terminology',
  'save-directory-groups',
  'save-group-chart-groups',
  'save-input-line-name-textarea',
  'save-ip-whitelist',
  'save-linked-content',
  'save-my-settings-button',
  'save-org-chart-groups',
  'save-organization-profile',
  'save-palette-picker-changes',
  'save-responsibility-card-button',
  'save-signature-permissions',
  'save-specialization-tag-terminology',
  'save-topic-cover-changes',
  'search-results-filter-button',
  'set-curriculum-order-button',
  'set-font-bold-editor-bubble-menu',
  'set-font-italic-editor-bubble-menu',
  'set-font-link-editor-bubble-menu',
  'set-font-strike-editor-bubble-menu',
  'share-template-button',
  'show-bulk-user-upload-modal',
  'show-confirmation-modal-button',
  'show-core-modal-button',
  'show-e-signature-modal-button',
  'show-more-favorite-curriculums',
  'show-slideout',
  'show-suggested-resps-primary',
  'show-suggested-resps-tertiary',
  'show-task-modal-button',
  'show-update-logo-modal-button',
  'show-update-password-modal-button',
  'side-nav-cta-buy-now',
  'smart-outline-generic-error-generate-button',
  'smart-outline-success-state-button',
  'smart-test-loading-modal-redirect-button',
  'sort-order-dropdown-button',
  'start-survey-button',
  'steps-slideout-button',
  'step-version-history-cancel',
  'step-version-history-submit',
  'storybook-testing-button',
  'subject-outline-add-content-button',
  'subjects-assigned-clear-completion-button',
  'subjects-assigned-done-button',
  'subjects-assigned-enter-assign-subjects-mode-button',
  'subjects-assigned-enter-clear-completion-mode-button',
  'subjects-assigned-enter-mark-complete-mode-button',
  'subjects-assigned-enter-unassign-subjects-mode-button',
  'subjects-assigned-mark-complete-button',
  'subjects-assigned-unassign-subjects-button',
  'submit-accept-invitation',
  'submit-import-teammates',
  'submit-survey-consumption-button',
  'success-import-invite-all',
  'success-import-skip-invites',
  'survey-submit-options-button',
  'survey-workspace-edit-test',
  'templates-outline-filter-apply-button',
  'templates-outline-filter-button',
  'templates-outline-filter-clear-button',
  'templates-outline-view-by-dropdown',
  'toggle-curriculums-outline-filters-button',
  'toggle-task-list-button',
  'toggle-users-outline-table-columns-button',
  'toggle-users-outline-table-filters-button',
  'training-path-overview-header-customize-button',
  'training-path-see-entire-tp-button',
  'trainual-plus-acknowledgement-checkbox-button',
  'trainual-plus-opt-in-button',
  'trainual-plus-talk-to-us-button',
  'try-gusto-free-button',
  'universal-login-submit-button',
  'update-finch-button',
  'update-password-button',
  'sign-out-from-all-devices-button',
  'update-payment-method-button',
  'upgrade-plan-cancel-button',
  'upgrade-plan-primary-button',
  'use-suggested-description',
  'user-assignment-report-filter-btn',
  'video-empty-state-action-button',
  'view-billing-history',
  'view-groups-button',
  'view-imported-curriculum',
  'view-mode-dropdown',
  'view-report-from-curriculum',
  'view-requests-button',
  'view-sso-button',
  'view-templates-button',
  'zoom-decrease',
  'zoom-increase',
  `add-content-split-button-${number}`,
  `approve-request-${number}`,
  `collapsed-element-primary-button-${string}`,
  `collapsed-element-secondary-button-${string}`,
  `curriculum-assignments-button-${number}`,
  `delegation-planner-add-responsibility-button-${number}`,
  `delegation-planner-cancel-add-responsibility-button-${number}`,
  `delegation-planner-cancel-responsibility-content-button-${number}`,
  `delegation-planner-save-responsibility-content-button-${number}`,
  `deny-request-${number}`,
  `inline-create-curriculum-element-button-${number}`,
  `inline-create-step-button-${number}`,
  `manage-completions-page-clear-button-${number}`,
  `manage-completions-page-complete-button-${number}`,
  `open-ai-course-option-${string}`,
  `print-curriculum-button`,
  `request-curriculum-${number}-access-button`,
  `request-curriculum-${number}-access-page-button`,
  `template-outline-link-${number}`,
  `update-step-external-link-${number}`,
  `upgrade-to-${string}-billing-plan`,
  `video-marketing-sidebar-dismiss`,
  `video-marketing-sidebar-learn-how`,
  `video-modal-upload`,
  `video-modal-external-link`,
] as const;

const menuIdRegistry = [
  'about-me-bio',
  `account-${string}-color-picker`,
  'activity-log-filter-flyout',
  'add-delegation-card-menu',
  'add-teammate-menu',
  'bio-image-flyout',
  'color-picker-logo-bg',
  'create-content-menu',
  'create-curriculum-menu',
  'curriculum-filters-flyout',
  'curriculum-share-menu',
  'delegation-planner-move-responsibility-popup',
  'delegation-planner-three-dot-menu',
  'download-org-chart-menu',
  'editor-cell-color-picker',
  'editor-image-flyout',
  'editor-text-color-picker',
  'element-top-nav-three-dot-menu',
  'email-csv-button',
  'email-csv-menu',
  'embed-iframe-flyout',
  'embed-link-flyout',
  'embedly-flyout',
  'file-flyout',
  `flowchart-connections-flyout-${string}`,
  `flowchart-connections-icons-flyout-${string}`,
  'font-awesome-icon-flyout',
  'group-edit-trigger-edit-group-modal',
  'highlight-text-color-picker',
  'horizontal-line-color',
  'owned-by-flyout',
  'search-results-filter-flyout',
  `shape-${string}-color-picker`,
  'storybook-menu-id',
  'subject-outline-add-content-menu',
  'survey-image-flyout',
  'table-flyout',
  'top-nav-universal-add-menu',
  'top-nav-language-selection-menu',
  'top-nav-user-menu',
  'topic-header-image-flyout',
  'topic-header-three-dot-menu',
  'trainual-capture-flyout',
  'trainual-flyout',
  'trainual-plus-add-to-plan-button',
  'users-table-flyout',
  'verify-status-flyout',
  'video-flyout',
  'video-content-type-edit-menu',
  `assigned-responsibility-linked-content-${string}`,
  `assigned-responsibility-manage-linked-content-${string}`,
  `board-three-dot-${number}`,
  `column-three-dot-${number}`,
  `connect-content-menu`,
  `connect-content-menu-options`,
  `curriculum-${number}`,
  `curriculum-edit-three-dot-${number}`,
  `curriculum-element-three-dot-${number}`,
  `delegation-planner-three-dot-menu-${number}`,
  `duration-time-setter-frequency-popup-${number || string}`,
  `editor-outline-content-step-three-dot-${number}`,
  `group-content-subject-${number}`,
  `group-member-${number}`,
  `group-three-dot-${number}`,
  `link-toolbar-flyout-${('bubble' || 'docked') as ToolbarContext}`,
  `nav-three-dot-${string}`,
  `reading-time-flyout-${number}`,
  `specialization-label-color-picker-${number}`,
  `specializations-three-dot-${number}`,
  `step-emoji-picker-${number}`,
  `step-external-link-three-dot-${number}`,
  `step-three-dot-${number}`,
  `team-three-dot-menu-button-${number}`,
  `user-${number}`,
] as const;

// Id format to follow td-[location/object]-[action]-[additional context if needed]
const threeDotMenuOptionIdRegistry = [
  'td-add-cover-image',
  'td-curriculum-archive',
  'td-curriculum-copy-link',
  'td-curriculum-delete',
  'td-curriculum-duplicate',
  'td-curriculum-edit',
  'td-curriculum-edit-copy-link',
  'td-curriculum-edit-delete',
  'td-curriculum-edit-edit',
  'td-curriculum-edit-view-report',
  'td-curriculum-element-copy-link',
  'td-curriculum-element-delete',
  'td-curriculum-element-duplicate',
  'td-curriculum-element-edit',
  'td-curriculum-element-export-pdf',
  'td-curriculum-element-show-print-pdf',
  'td-curriculum-element-move',
  'td-curriculum-element-rename',
  'td-curriculum-element-signature',
  'td-curriculum-export-pdf',
  'td-curriculum-show-print-pdf',
  'td-curriculum-manage-completions',
  'td-curriculum-move',
  'td-curriculum-rename',
  'td-curriculum-unarchive',
  'td-delegation-board-column-remove',
  'td-delegation-board-delete',
  'td-delegation-board-name-edit',
  'td-delegation-board-responsibility-edit',
  'td-delegation-board-responsibility-remove',
  'td-details-responsibility-modal-edit-terminology',
  'td-export-manage-people-table-csv',
  'td-groups-edit-content-table-edit-subject',
  'td-groups-edit-content-table-remove-subject',
  'td-groups-edit-header-trigger-edit-modal',
  'td-groups-edit-members-table-edit-user',
  'td-groups-edit-members-table-make-manager',
  'td-groups-edit-members-table-remove-user-from-group',
  'td-groups-edit-responsibilities-table-edit-responsibility',
  'td-groups-edit-responsibilities-table-remove-responsibility',
  'td-groups-index-delete-group',
  'td-groups-index-edit-group',
  'td-org-chart-download-pdf',
  'td-org-chart-download-png',
  'td-org-chart-edit',
  'td-profile-bio-delete',
  'td-profile-bio-edit',
  'td-remove-cover-image',
  'td-storybook-testing',
  'td-subject-export-pdf',
  'td-subject-show-print-pdf',
  'td-subject-notify-team',
  'td-team-delete',
  'td-team-edit',
  'td-users-outline-add-teammate',
  'td-users-outline-add-with-hr-software',
  'td-users-outline-add-with-payroll-integration-source',
  'td-users-outline-archive-user',
  'td-users-outline-bulk-add',
  'td-users-outline-delete-user',
  'td-users-outline-edit-user',
  'td-users-outline-resend-invite-to-user',
  'td-users-outline-send-all-invites',
  'td-users-outline-send-invite-to-user',
  'td-users-outline-unarchive-user',
  `step-${number}-add-to-chrome`,
  `step-${number}-copy-link`,
  `step-${number}-delete`,
  `step-${number}-duplicate`,
  `step-${number}-edit`,
  `step-${number}-move`,
  `step-${number}-rename`,
  `step-external-link-delete`,
  `step-external-link-edit`,
  `td-add-content-add-button-${number}`,
  `td-add-content-create-button-${number}`,
  `td-delegation-board-responsibility-edit-${number}`,
  `td-delegation-board-responsibility-remove-${number}`,
] as const;

const menuOptionIdRegistry = [
  'add-content-course-dropdown',
  'add-content-flowchart-dropdown',
  'add-content-survey-dropdown',
  'add-content-video-dropdown',
  'connect-from-trainual',
  'connect-from-url',
  'create-new-curriculum',
  'curriculum-option',
  'delegation-planner-add-group-card',
  'delegation-planner-add-user-card',
  'document-option',
  'editor-bulleted-list',
  'editor-numbered-list',
  'editor-task-list',
  'email-csv',
  'email-e-sign-files',
  'flowchart-option',
  'from-scratch-option',
  'from-template-option',
  'import-curriculum-from-document',
  'slideout-toggle-survey-options-button',
  'subject-export-pdf',
  'subject-notify-team',
  'subject-share-option',
  'top-nav-menu-add-template',
  'top-nav-menu-create-group',
  'top-nav-menu-create-subject',
  'top-nav-menu-dark-mode',
  'top-nav-menu-download-apps',
  'top-nav-menu-enroll-trainual-university',
  'top-nav-menu-help',
  'top-nav-menu-import-document',
  'top-nav-menu-invite-teammate',
  'top-nav-menu-my-settings',
  'top-nav-menu-refer-a-friend',
  'top-nav-menu-sign-out',
  'top-nav-menu-switch-account',
  'top-nav-menu-language-select',
] as const;

const modalIdRegistry = [
  `delete-step-modal-${number}`,
  `duplicate-step-modal-${number}`,
  `manage-extensions-modal-${number}`,
  'manage-following-modal',
  `move-step-modal-${number}`,
  `restrict-move-modal-${number}`,
  'update-organization-logo-modal',
  'training-path-destroy-modal',
  'training-path-destroy-draft-modal',
  'super-share-modal',
  'manage-completions',
] as const;

const idRegistry = [
  ...buttonIdRegistry,
  ...menuIdRegistry,
  ...threeDotMenuOptionIdRegistry,
  ...menuOptionIdRegistry,
  ...modalIdRegistry,
] as const;

type SplitButtonAction = 'close-menu' | 'open-menu';
type SpitButtonId = `${BaseId}-${SplitButtonAction}`;
type SsoTypes = 'okta-sso-login' | 'ms-sso-login' | 'google-sso-login' | 'saml-sso-login';
type LanguageButton = 'select-language-button';
export type BaseId = (typeof idRegistry)[number];
export type RegisteredMenuId = (typeof menuIdRegistry)[number];
export type ThreeDotMenuOptionRegisteredId = (typeof threeDotMenuOptionIdRegistry)[number];
export type MenuOptionRegisteredId = (typeof menuOptionIdRegistry)[number];
export type RegisteredModalId = (typeof modalIdRegistry)[number];
export type RegisteredId = BaseId | HeapModalActionId | SpitButtonId | SsoTypes | LanguageButton;

type Registry = {
  [key in RegisteredId]: boolean;
};

class IdRegistry {
  registry: Registry;

  constructor() {
    if (this.isEnabled()) {
      this.validateNoDups();
      this.registry = this.initialRegisty();
    }
  }

  get(key: RegisteredId) {
    if (this.isEnabled()) {
      const registeredId = this.registry[key];
      if (registeredId) {
        this.throwError(`All ids must be unique. id: '${key}' is already in use.`);
      } else {
        this.registry[key] = true;
      }
    }
  }

  resetKey(key: RegisteredId) {
    if (this.isEnabled()) {
      const registeredId = this.registry[key];
      if (registeredId) {
        this.registry[key] = false;
      }
    }
  }

  private initialRegisty() {
    return idRegistry.reduce((obj, id) => ({ ...obj, [id]: false }), {} as Registry);
  }

  private validateNoDups() {
    const idRegistrySet = new Set(idRegistry);
    if (idRegistrySet.size !== idRegistry.length) {
      idRegistrySet.forEach((value) => {
        const dupCount = idRegistry.reduce(function (a, e) {
          if (e === value) a++;
          return a;
        }, 0);
        if (dupCount > 1)
          return this.throwError(`Warning - id: '${value}' is listed more than once in idRegistry`);
      });
    }
  }

  private throwError(error: string) {
    if (this.isEnabled()) {
      alert(error);
    }
  }

  private isEnabled(): boolean {
    return !this.isStorybookEnvironment() && this.isLocalEnvironment();
  }

  private isLocalEnvironment(): boolean {
    return process.env.NODE_ENV === 'development';
  }

  private isStorybookEnvironment(): boolean {
    return process.env.STORYBOOK_ENV === 'true';
  }
}

export default new IdRegistry();
