import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { deprecated } from '../../../styled/TypeSystem';
import { BadgeFontWeight, BadgeType, Size } from './BadgeTypes';
import { useBadgeStyles } from './useBadgeStyles';

export interface Props {
  type: BadgeType;
  className?: string;
  size?: Size;
  text?: string;
  icon?: ReactNode;
  fontWeight?: BadgeFontWeight;
  clickable?: boolean;
  forwardRef?: React.RefObject<HTMLSpanElement>;
  hasBorder?: boolean;
}

const StyledBadge = styled.span<{
  fontWeight: BadgeFontWeight;
  type: BadgeType;
  badgeStyles: { [cssProp: string]: string };
  clickable?: boolean;
  hasBorder?: boolean;
}>`
  font-weight: ${({ fontWeight, theme: { constants } }) =>
    fontWeight === 'medium' ? constants.fontMedium : constants.fontRegular};
  background-color: ${({ type, theme: { vars } }) => getBackgroundColor(type, vars)};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  color: ${({ type, theme: { vars } }) => getFontColor(type, vars)};
  width: max-content;
  max-width: min(100%, 15rem);
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  ${({ hasBorder, theme: { constants, vars } }) =>
    hasBorder && `box-shadow: inset 0 0 0 ${constants.borderWidthSm} ${vars.borderDefault};`}
  ${({ badgeStyles }) => badgeStyles};
  ${deprecated.fontSm3};
`;

const getBackgroundColor = (type: BadgeType, vars: { [key: string]: string }) => {
  switch (type) {
    case 'trainual-purple':
      return vars.stateBadgeTrainual;
    case 'error':
      return vars.stateBadgeError;
    case 'caution':
      return vars.stateBadgeCaution;
    case 'success':
      return vars.stateBadgeSuccess;
    case 'info':
      return vars.stateBadgeInfo;
    case 'general':
      return vars.stateBadgeGeneral;
    case 'brand':
      return vars.accentPrimaryDefault;
    case 'light':
      return vars.foundationSurface1;
  }
};

const getFontColor = (type: BadgeType, vars: { [key: string]: string }) => {
  switch (type) {
    case 'error':
    case 'caution':
    case 'success':
    case 'info':
    case 'general':
    case 'light':
    case 'trainual-purple':
      return vars.textDefault;
    case 'brand':
      return vars.textSurface;
  }
};

const Badge = ({
  fontWeight = 'medium',
  type,
  className,
  size = 'md',
  text,
  icon,
  clickable,
  hasBorder,
  forwardRef,
}: Props) => {
  const badgeText = size !== 'circle' ? text : '';
  const badgeIcon = size !== 'circle' ? icon : null;

  const badgeStyles = useBadgeStyles(size);

  return (
    <StyledBadge
      badgeStyles={badgeStyles}
      className={className}
      clickable={clickable}
      fontWeight={fontWeight}
      hasBorder={hasBorder}
      ref={forwardRef}
      type={type}
    >
      {badgeIcon}
      <span className='badgeText'>{badgeText}</span>
    </StyledBadge>
  );
};

export default Badge;
