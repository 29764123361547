import { css } from 'styled-components';

const fontSm1 = css`
  font-size: 0.555rem;
  line-height: 0.656rem;
`;

export const fontSm2 = css`
  font-size: 0.624rem;
  line-height: 0.781rem;
`;

const fontSm3 = css`
  font-size: 0.702rem;
  line-height: 0.881rem;
`;

export const fontSm4 = css`
  font-size: 0.79rem;
  line-height: 1rem;
`;

export const fontSm5 = css`
  font-size: 0.889rem;
  line-height: 1.125rem;
`;

export const fontMd1 = css`
  font-size: 1rem;
  line-height: 1.25rem;
`;

const fontMd2 = css`
  font-size: 1.125rem;
  line-height: 1.437rem;
`;

export const fontMd3 = css`
  font-size: 1.266rem;
  line-height: 1.562rem;
`;

const fontMd4 = css`
  font-size: 1.424rem;
  line-height: 1.812rem;
`;

export const fontMd5 = css`
  font-size: 1.602rem;
  line-height: 2rem;
`;

export const fontLg1 = css`
  font-size: 1.802rem;
  line-height: 2.25rem;
`;

export const fontLg2 = css`
  font-size: 2.027rem;
  line-height: 2.562rem;
`;

const fontLg3 = css`
  font-size: 2.281rem;
  line-height: 2.875rem;
`;

const fontLg4 = css`
  font-size: 2.566rem;
  line-height: 3.25rem;
`;

export const deprecated = {
  fontSm1,
  fontSm3,
  fontMd2,
  fontMd4,
  fontLg3,
  fontLg4,
};
