import parse from 'html-react-parser';
import { TranslateOptions } from 'i18n-js';

import { I18n } from '../../entrypoints/support';

const GLOBAL_PREFIX = 'react';

export default function initTranslations(prefixKey = '') {
  const prefix = prefixKey.length > 0 ? `${prefixKey}.` : prefixKey;

  return (key: string, options: TranslateOptions = {}): string => {
    if (options.productTerm && !options.skipNoTranslate) {
      const productTerm = options.productTerm;
      options.productTerm = `<span class='notranslate'> ${productTerm} </span>`;
      return parse(
        `<span>${I18n.t(`${GLOBAL_PREFIX}.${prefix}${key}`, options)}</span>`.replace(
          new RegExp(`${productTerm} </span>([.,?!])`, 'g'),
          `${productTerm}</span>$1`
        )
      );
    }
    return I18n.t(`${GLOBAL_PREFIX}.${prefix}${key}`, options);
  };
}
